<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI label="Total streamers" :value="totalUsers" title="Total amount of streamers" />

      <KPI
        label="Total sponsorships"
        :value="totalSponsorships"
        title="Total amount of microsponsorship (streamer is counted once for each campaign they are part of)"
      />

      <KPI
        label="Active streamers part of campaign"
        :value="activeUsers"
        title="Amount of streamers that are part of an active campaign"
      />

      <KPI
        label="Historic streamers part of campaign"
        :value="distinctJoined"
        title="Amount of streamers that historically have been part of a campaign"
      />

      <KPI label="Joined today" :value="joinedToday" title="Amount of streamers that has joined today" />

      <KPI
        label="Joined last 7 days"
        :value="joinedThisWeek"
        title="Amount of streamers that has joined in the last 7 days"
      />

      <KPI
        label="Joined last 30 days"
        :value="joinedThisMonth"
        title="Amount of streamers that has joined in the last 30"
      />

      <KPI
        label="Joined last 365 days"
        :value="joinedThisYear"
        title="Amount of streamers that has joined in the last 365 days"
      />

      <KPI
        label="Streamers with language"
        :value="withLanguage"
        title="Amount of streamers that has broadcasting language set"
      />
    </div>

    <Title title="User activity" size="medium" class="mb-4" />

    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI
        label="Average days visited"
        :value="userActivity.averageUserVisits ? userActivity.averageUserVisits.toFixed(2) : 'N/A'"
      />

      <KPI label="Median days visited" :value="userActivity.medianUserVisits" />

      <KPI
        label="Average page visits"
        :value="userActivity.averagePageVisits ? userActivity.averagePageVisits.toFixed(2) : 0"
      />

      <KPI label="Median page visits" :value="userActivity.medianPageVisits" />

      <KPI
        v-for="interaction of userInteractions"
        :key="interaction.id"
        :label="interaction.label"
        :value="interaction.count"
        title="as of 3. June 2022"
      />
    </div>

    <Title title="User goals" size="medium" class="mb-4" />

    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI label="Users with goals set" :value="usersWithGoals" />

      <KPI
        v-for="userGoal of userGoals"
        :key="userGoal._id"
        :label="userGoal.goal[0]"
        :value="parseFloat(userGoal.percentage.toFixed(2))"
        unit="%"
      />
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
      <div>
        <div class="flex items-center">
          <Title size="medium" title="User growth by day" />

          <Button
            class="ml-auto"
            :label="userGrowthTotal ? 'Total' : 'Last 30 days'"
            kind="secondary"
            @clicked="userGrowthTotal = !userGrowthTotal"
          />
        </div>

        <AreaChart
          v-if="userGrowthGraph && userGrowthGraph.series && userGrowthGraph.labels"
          :series="userGrowthGraph.series"
          :labels="userGrowthGraph.labels"
        />
      </div>

      <div>
        <Title size="medium" title="User growth by month" />

        <AreaChart
          v-if="userGrowthMonthly && userGrowthMonthly.series && userGrowthMonthly.labels"
          :series="userGrowthMonthly.series"
          :labels="userGrowthMonthly.labels"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AreaChart, KPI, Button, Title } from "cavea-design-system";
import calculateGrowthPercentage from "@/lib/calculateGrowthPercentage";

export default {
  name: "AdminStatistics",

  metaInfo: {
    title: "User Statistics",
  },

  components: {
    AreaChart,
    KPI,
    Button,
    Title,
  },

  data() {
    return {
      totalUsers: 0,
      joinedToday: 0,
      joinedThisWeek: 0,
      joinedThisMonth: 0,
      joinedThisYear: 0,
      distinctJoined: 0,
      totalSponsorships: 0,
      withLanguage: 0,

      userGrowth: null,
      userGrowthTotal: false,

      userGoals: [],
      usersWithGoals: 0,

      userActivity: {
        uniqueUsers: 0,
        averagePageVisits: 0,
        medianPageVisits: 0,
        averageUserVisits: 0,
        medianUserVisits: 0,
      },

      userInteractions: [],

      activeUsers: 0,
    };
  },

  computed: {
    userGrowthGraph() {
      if (this.userGrowth?.labels?.length) {
        return {
          series: [
            {
              name: "Total users",
              data: this.userGrowthTotal
                ? this.userGrowth?.total
                : this.userGrowth?.total.slice(this.userGrowth?.total.length - 30, this.userGrowth?.total.length),
            },
            {
              name: "New users",
              data: this.userGrowthTotal
                ? this.userGrowth?.count
                : this.userGrowth?.count.slice(this.userGrowth?.count.length - 30, this.userGrowth?.count.length),
            },
          ],
          labels: this.userGrowthTotal
            ? this.userGrowth.labels
            : this.userGrowth?.labels.slice(this.userGrowth?.labels.length - 30, this.userGrowth?.labels.length),
        };
      }

      return null;
    },

    userGrowthMonthly() {
      if (this.userGrowth?.growth?.length) {
        const months = [];
        const totals = [];
        const totalUserGrowth = [];
        const newUsers = [];

        let currentDateIndex = 0;
        for (let i = 0; i < this.userGrowth.labels.length; i += 1) {
          const monthKey = new Date(
            new Date(this.userGrowth.labels[i]).getFullYear(),
            new Date(this.userGrowth.labels[i]).getMonth() + 1,
            0
          ).toISOString();

          if (i === 0) {
            months.push(monthKey);

            totals.push(0);
            newUsers.push(0);
            totalUserGrowth.push(0);
          }

          if (months[currentDateIndex] === monthKey) {
            totals[currentDateIndex] = this.userGrowth?.total[i] ?? 0;
            newUsers[currentDateIndex] =
              totals[currentDateIndex] - (currentDateIndex > 0 ? totals[currentDateIndex - 1] : 0);
          } else {
            currentDateIndex += 1;

            months.push(monthKey);

            newUsers.push(this.userGrowth.count[i] ?? 0);
            totals.push(this.userGrowth.total[i] ?? 0);
          }

          totalUserGrowth[currentDateIndex] =
            currentDateIndex > 0
              ? parseFloat(
                  calculateGrowthPercentage(totals[currentDateIndex - 1], totals[currentDateIndex])?.toFixed(2)
                )
              : 0;
        }

        return {
          series: [
            {
              name: "Total users",
              data:
                months.length > 12 ? totals.slice(months.length > 12 ? months.length - 12 : 0, months.length) : totals,
            },
            {
              name: "New users",
              data:
                months.length > 12
                  ? newUsers.slice(months.length > 12 ? months.length - 12 : 0, months.length)
                  : newUsers,
            },
            {
              name: "User growth %",
              data:
                months.length > 12
                  ? totalUserGrowth.slice(months.length > 12 ? months.length - 12 : 0, months.length)
                  : totalUserGrowth,
            },
          ],
          labels:
            months.length > 12 ? months.slice(months.length > 12 ? months.length - 12 : 0, months.length) : months,
        };
      }

      return null;
    },
  },

  created() {
    this.fetchUserGrowth();
    this.fetchOverallStatistics();
    this.fetchTotalUsers();
    this.fetchUserGoals();

    this.fetchUserActivity();
    this.fetchCampaignActivity();

    this.fetchUserInteractions();
  },

  methods: {
    async fetchTotalUsers() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/user-stats/users`;
      const response = await axios
        .get(URL)
        .then((res) => res?.data)
        .catch((error) => {
          console.error("Error fetching total users", error);
          return null;
        });

      this.totalUsers = response?.totalUsers ?? 0;
      this.joinedToday = response?.joinedToday ?? 0;
      this.joinedThisWeek = response?.joinedThisWeek ?? 0;
      this.joinedThisMonth = response?.joinedThisMonth ?? 0;
      this.joinedThisYear = response?.joinedThisYear ?? 0;
      this.withLanguage = response?.withLanguage ?? 0;
    },

    async fetchOverallStatistics() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/user-stats/campaigns`;

      const response = await axios
        .get(URL)
        .then((res) => res?.data)
        .catch((err) => {
          console.error("fetchOverallStatistics ERROR", err);
        });

      this.distinctJoined = response?.distinctJoined ?? 0;
      this.totalSponsorships = response?.totalSponsorships ?? 0;
    },

    async fetchUserGrowth() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/users/growth`;

      const userGrowth = await axios
        .get(URL)
        .then((result) => result?.data?.userGrowth ?? [])
        .catch((error) => {
          console.error("fetchUserGrowth error", error);
          return error?.response?.data?.userGrowth ?? [];
        });

      const total = [];
      const count = [];
      const date = [];
      const growth = [];

      if (userGrowth?.length) {
        for (let i = 0; i < userGrowth?.length; ++i) {
          userGrowth[i].total =
            i > 0 ? (userGrowth[i - 1]?.total ?? 0) + (userGrowth[i]?.count ?? 0) : userGrowth[i]?.count ?? 0;

          userGrowth[i].growthPercentage =
            i > 0 ? calculateGrowthPercentage(userGrowth[i - 1]?.total, userGrowth[i]?.total) : 0;

          if (userGrowth[i]?.date) {
            date.push(userGrowth[i].date);
            count.push(parseInt(userGrowth[i]?.count, 10) ?? 0);
            total.push(parseInt(userGrowth[i]?.total, 10) ?? 0);
            growth.push(userGrowth[i]?.growthPercentage ?? 0);
          }
        }
      }

      this.userGrowth = {
        total,
        count,
        growth,
        labels: date,
      };
    },

    async fetchUserGoals() {
      const userGoals = await axios
        .get(`${process.env.VUE_APP_API_URL}/admin/users/goals`)
        .then((res) => res?.data)
        .catch((error) => {
          console.error("Error getting user goals", error);

          return error?.response?.data;
        });

      this.userGoals = userGoals?.goals ?? [];
      this.usersWithGoals = userGoals?.total ?? 0;
    },

    async fetchUserActivity() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/logs/activity`)
        .then((res) => {
          this.userActivity = res?.data;
        })
        .catch((error) => {
          this.userActivity = error?.response?.data;
        });
    },

    async fetchCampaignActivity() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/ad-campaigns/creators/active`)
        .then((res) => {
          this.activeUsers = res?.data?.activeUsers;
        })
        .catch((error) => {
          this.activeUsers = error?.response?.data?.activeUsers;
        });
    },

    async fetchUserInteractions() {
      const userInteractions = await axios
        .get(`${process.env.VUE_APP_API_URL}/logs/clicks`)
        .then((res) => res?.data?.clicks ?? [])
        .catch((_error) => []);

      for (let i = 0; i < userInteractions.length; i += 1) {
        if (userInteractions[i]?._id?.target === "button-streamer-dashboard-copy-browser-source") {
          this.userInteractions = [
            {
              id: userInteractions[i]?._id?.target,
              count: userInteractions[i].count,
              label: "Copied Browser Source",
            },
          ];
        }
      }
    },
  },
};
</script>
